import React from "react";
import {Route, Switch, Redirect, withRouter} from 'react-router-dom';
import './assets/css/theme/base.css';
import './assets/css/theme/login-6.css';
import './App.css';
import Login from "./components/Login/Login";
import Aux from "./hoc/AuxWrapper";
import {AUTH_TOKEN} from "./shared/utility";
import Nav from "./components/Navbar/Nav";
import Footer from "./components/Footer/Footer";
import Logout from "./pages/Logout";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import MainPrAreasTable from "./components/MainPrAreas/MainPrAreasTable";
import DonorsTable from "./components/Donors/DonorsTable";
import SectorsTable from './components/Sectors/SectorsTable';
import ProjectRegistryTable from './components/ProjectRegistry/ProjectRegistryTable';
import AddNewProject from './components/ProjectRegistry/AddNewProject';
import EditProject from './components/ProjectRegistry/EditProject';
import ChangePassword from './components/ChangePassword/ChangePassword';
import UsersTable from './components/UsersCrud/UsersTable';
import ReportsTable from './components/Report/ReportsTable';
import InterventionTypesTable from './components/InterventionTypes/InterventionTypesTable';
import SourceOfFinancingTable from "./components/SourceOfFinancing/SourceOfFinancingTable";
import ImplOrg from "./components/ImplementingOrganization/ImplOrg";
import RegisterNewUserOrganization from "./components/RegisterNewUserOrganization/RegisterNewUserOrganization";
import ContactSystemAdmin from "./components/RegisterNewUserOrganization/ContactSystemAdmin";



class App extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            role: "",
            data: {}
        }
    }

        render()
        {
        let routes = (
            <Switch>
                <Route path="/login" component={Login}/>
                <Route path="/reset/password" exact component={ResetPassword}/>
                <Route path="/registerNewUser" exact component={RegisterNewUserOrganization}/>
                <Route path="/contactsysadmin" exact component={ContactSystemAdmin}/>
                <Redirect to="/login"/>

            </Switch>
        );
        let authToken = localStorage.getItem(AUTH_TOKEN);
        let userRole = localStorage.getItem('role');
        if (authToken && authToken !== '') {
            if (userRole === "ROLE_ADMIN") {
                routes = this.adminRoutes();
            } else if (userRole === "ROLE_USER") {
                routes = this.userRoutes();
            } 
            else if (userRole === "ROLE_IMPLEMENTATOR") {
                routes = this.implementatorRoutes();
            }
        }

        return (
            <Aux>{routes}</Aux>
        )
    }

    adminRoutes = () => {
        return (<Aux>
            <Nav/>
            <div>

                <Switch>
                    <Route path="/" exact component={ProjectRegistryTable}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/mainPrAreas" component={MainPrAreasTable}/>
                    <Route path="/donors" component={DonorsTable}/>
                    <Route path="/sectors" component={SectorsTable}/>
                    <Route path="/implementingOrganizations" component={ImplOrg}/>
                    <Route path="/projectRegistry" exact component={ProjectRegistryTable}/>
                    <Route path="/projectRegistry/add" exact component={AddNewProject}/>
                    <Route path="/projectRegistry/edit/:id" exact component={EditProject}/>
                    <Route path="/changePassword" exact component={ChangePassword}/>
                    <Route path="/users" exact component={UsersTable}/>
                    <Route path="/reports" exact component={ReportsTable}/>
                    <Route path="/interventions" exact component={InterventionTypesTable}/>
                    <Route path="/sourceOfFinancing" exact component={SourceOfFinancingTable}/>
                </Switch>
            </div>
            <Footer/>
        </Aux>);
    };
    implementatorRoutes = () => {
        return (<Aux>
            <Nav/>
            <div>
                <Switch>
                    <Route path="/" exact component={ProjectRegistryTable}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/projectRegistry" exact component={ProjectRegistryTable}/>
                    <Route path="/projectRegistry/add" exact component={AddNewProject}/>
                    <Route path="/projectRegistry/edit/:id" exact component={EditProject}/>
                    <Route path="/changePassword" exact component={ChangePassword}/>
                    <Route path="/reports" exact component={ReportsTable}/>
                </Switch>
            </div>
            <Footer/>
        </Aux>)
    };
    userRoutes = () => {
        return (<Aux>
            <Nav/>
            <div>
                <Switch>
                    <Route path="/" exact component={ProjectRegistryTable}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/projectRegistry" exact component={ProjectRegistryTable}/>
                    <Route path="/changePassword" exact component={ChangePassword}/>
                    <Route path="/reports" exact component={ReportsTable}/>
                </Switch>
            </div>
            <Footer/>
        </Aux>)
    };
}

export default withRouter(App);

