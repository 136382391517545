import axios from '../../axios/app';
import moment from 'moment'


const ProjectRegistryService = {

    getProjectsPaginatedByYear: (numPage, year, keyword) => {
        // axios povik so godinata kako prop

        return axios.get(`/rest/projectRegistry/pageable?currentYear=${year}&keyword=${keyword}&page=${numPage}&size=5`)
    },

    getProjectsPaginatedWithoutYear: (numPage, keyword) => {
        // axios povik bez godina

        return axios.get(`/rest/projectRegistry/all-paged?keyword=${keyword}&page=${numPage}&size=5`)
    },

    editProject: (newProject, formData) => {

        return axios.patch(`/rest/projectRegistry/${newProject.id}`, formData, {

            params: {
                sectorId: newProject.sector.id,
                romaIntegration: newProject.romaIntegration,
                impactDescription: newProject.impactDescription,
                currency: newProject.currency,

                mainPrAreaId: newProject.mainPrAreas.id,
                otherPrArea: newProject.otherPrArea,
                title: newProject.title,
                description: newProject.descr,
                totalMonths: newProject.totalMonths,

                startDate: moment(newProject.startDate).format('MMM-DD-YYYY'),
                endDate: moment(newProject.endDate).format('MMM-DD-YYYY'),

                donorId: newProject.donor.id,
                implementingPartner: newProject.implementingPartner,
                implOrg: newProject.implOrg.id,
                totalAmount: newProject.totalAmount,
                nextYearAmount: newProject.nextYearAmount,
                currentYearAmount: newProject.currentYearAmount,
                furtherYearsAmount: newProject.furtherYearsAmount,
                nationalCofinanceAmount: newProject.nationalCofinanceAmount,
                comment: newProject.comment,
                currentYear: newProject.currentYear,
                systemObstacles: newProject.systemObstacles,
            }
        });


    },

    addNewProject: (newProject, formData) => {
        return axios.post('/rest/projectRegistry', formData, {
            params: {
                sectorId: newProject.sector.id,
                romaIntegration: newProject.romaIntegration,
                mainPrAreaId: newProject.mainPrAreas.id,
                otherPrArea: newProject.otherPrArea,

                impactDescription: newProject.impactDescription,
                currency: newProject.currency,

                title: newProject.title,
                description: newProject.descr,
                totalMonths: newProject.totalMonths,

                startDate: moment(newProject.startDate).format('MMM-DD-YYYY'),
                endDate: moment(newProject.endDate).format('MMM-DD-YYYY'),

                donorId: newProject.donor.id,
                implementingPartner: newProject.implementingPartner,
                implAgencyOrg: newProject.implAgencyOrg,
                totalAmount: newProject.totalAmount,
                nextYearAmount: newProject.nextYearAmount,
                currentYearAmount: newProject.currentYearAmount,
                furtherYearsAmount: newProject.furtherYearsAmount,
                nationalCofinanceAmount: newProject.nationalCofinanceAmount,
                comment: newProject.comment,
                currentYear: newProject.currentYear,
                systemObstacles: newProject.systemObstacles,

            }
        });
    },

    getAllYears: () => {
        return axios.get('rest/projectRegistry/currentYears');
    },

    getProjectById: (projectId) => {
        return axios.get(`/rest/projectRegistry/${projectId}`);
    },

    getDefaultReport: (MKDtoEUR, USDtoEUR, currentYear) => {
        return axios.get('/rest/projectRegistry/generateReport/default', {
            params: {
                MKDtoEUR: MKDtoEUR,
                USDtoEUR: USDtoEUR,
                currentYear: currentYear
            }
        })
    },

    getReport: (sectorId, donorId, mainPrAreaId, MKDtoEUR, USDtoEUR, type, currentYear) => {

        if (type === 'Sector') {
            return axios.get('/rest/projectRegistry/generateReport/bySector', {
                params: {
                    sectorId: sectorId,
                    MKDtoEUR: MKDtoEUR,
                    USDtoEUR: USDtoEUR,
                    currentYear: currentYear
                }
            })
        } else if (type === 'Donor') {
            return axios.get('/rest/projectRegistry/generateReport/byDonor', {
                params: {
                    donorId: donorId,
                    MKDtoEUR: MKDtoEUR,
                    USDtoEUR: USDtoEUR,
                    currentYear: currentYear
                }
            })
        } else if (type === 'Area') {
            return axios.get('/rest/projectRegistry/generateReport/byMainPrArea', {
                params: {
                    mainPrAreaId: mainPrAreaId,
                    MKDtoEUR: MKDtoEUR,
                    USDtoEUR: USDtoEUR,
                    currentYear: currentYear
                }
            })
        } else if (type === 'SectorAndDonor') {
            return axios.get('/rest/projectRegistry/generateReport/bySectorAndDonor', {
                params: {
                    sectorId: sectorId,
                    donorId: donorId,
                    MKDtoEUR: MKDtoEUR,
                    USDtoEUR: USDtoEUR,
                    currentYear: currentYear
                }
            })

        } else if (type === 'SectorAndArea') {
            return axios.get('/rest/projectRegistry/generateReport/bySectorAndMainPrArea', {
                params: {
                    sectorId: sectorId,
                    mainPrAreaId: mainPrAreaId,
                    MKDtoEUR: MKDtoEUR,
                    USDtoEUR: USDtoEUR,
                    currentYear: currentYear
                }
            })
        } else if (type === 'DonorAndArea') {
            return axios.get('/rest/projectRegistry/generateReport/byDonorAndMainPrArea', {
                params: {
                    donorId: donorId,
                    mainPrAreaId: mainPrAreaId,
                    MKDtoEUR: MKDtoEUR,
                    USDtoEUR: USDtoEUR,
                    currentYear: currentYear
                }
            })
        } else if (type === 'SectorAndDonorAndArea') {
            return axios.get('/rest/projectRegistry/generateReport/bySectorDonorMainPrArea', {
                params: {
                    sectorId: sectorId,
                    donorId: donorId,
                    mainPrAreaId: mainPrAreaId,
                    MKDtoEUR: MKDtoEUR,
                    USDtoEUR: USDtoEUR,
                    currentYear: currentYear
                }
            })
        }
    }

}

export default ProjectRegistryService;