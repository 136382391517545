import React, {Component} from 'react';
import ReactPaginate from 'react-paginate';
import UserService from '../../repository/service/UserService';
import CrudModal from '../Crud/CrudModal';
import EditUsers from './EditUsers';
import AddUsers from './AddUsers';
import DeleteModal from "../Crud/DeleteModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";

class UsersTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            pageCount: 1,
            role: '',

        }

    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = (selectedPage = 0) => {
        UserService.getUsersPaginated(selectedPage).then((resp) => {

            this.setState({
                users: resp.data.content,
                pageCount: resp.data.totalPages
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handlePageClick = (data) => {
        this.fetchData(data.selected);
    }

    addUser = (newUser) => {
        return UserService.addUser(newUser).then(resp => {
            this.fetchData();
            toast.success("Successfully added new user.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() => {
            toast.error("There is a problem adding a new user.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }

    editUserByAdmin = (editUser) => {
        return UserService.editUserByAdmin(editUser).then(resp => {
            this.fetchData();
            toast.success("Successfully edited this user.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() => {
            toast.error("There is a problem editing this user.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }


    doDelete = (id) => {
        return UserService.deleteUserById(id).then(resp => {
            this.fetchData();
            toast.success("Successfully deleted user.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() => {
            toast.error("There is a problem deleting this user.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }

    renderUsers = (user) => {
        return (

                <tr key={user.userId}>

                    <td>{user.name}</td>
                    <td>{user.surname}</td>
                    <td>{user.telephone}</td>
                    <td>{user.email}</td>
                    <td>{user.orgName ? user.orgName : null}</td>

                    <td>

                        <CrudModal title='Edit'
                                   modalTitle='Edit user'
                                   entity={user}
                                   onSubmit={this.editUserByAdmin}
                                   body={EditUsers}
                                   btnClass='btn-success btn-sm'
                        />

                        <DeleteModal
                            prompt="Are you sure you want to delete this user ?"
                            doDelete={() => this.doDelete(user.userId)}
                            btnClass='ml-2'
                        />
                        <button className="btn btn-warning btn-sm ml-2"
                                onClick={() => this.sendResetPass(user.email)}><FontAwesomeIcon icon={faEnvelope} size="lg" style={{paddingRight:'4px'}}/>Reset Password
                        </button>
                    </td>
                </tr>
        )
    }


    render() {
        return (
            <div className="container">

                <div className="container pt-4 pb-4">
                    <CrudModal title='Add'
                               modalTitle='Add new user'
                               entity={{}}
                               onSubmit={this.addUser}
                               body={AddUsers}
                               btnClass='btn-primary'/>
                </div>


                <table className="table table-hover table-bordered">
                    <thead className='thead-dark'>
                    <tr>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>Telephone</th>
                        <th>Email</th>
                        <th>Implementing Organization</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.users.map(this.renderUsers)}
                    </tbody>
                </table>
                <div className="container-fluid">
                    <ReactPaginate
                        previousLabel={'prev'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        )
    }

    sendResetPass = (email) => {

        UserService.resetPassword(email).then(resp => {
            this.fetchData();
            toast.success("Successfully sent mail.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() => {
            toast.error("There is a problem sending mail.", {
                position: "top-center",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })

    }
}

export default UsersTable;