import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import '../../assets/css/theme/base.css'
import {faLock, faTools}  from "@fortawesome/free-solid-svg-icons";
import * as images from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class Nav extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userRole: localStorage.getItem('role'),
            menuShown: false,
            active: 'ProjectRegistry',
        }
    }


    toggleSidebar() {
        let menuClass = '';
        let toggleClass = '';

        if (this.state.menuShown) {
            menuClass = 'menu';
            toggleClass = '';
        } else {
            menuClass = 'menu menu_shown';
            toggleClass = 'active'
        }

        document.getElementById('menu').setAttribute('class', menuClass);
        document.getElementById('nav-toggle').setAttribute('class', toggleClass);

        this.setState((prevState) => ({
            menuShown: !prevState.menuShown
        }));
    }

    setActiveItem(label) {
        this.setState({
            active: label
        })
    }



    itemDisplay = (item, index) => {
        return (

            <NavLink key={index}
                     style={{
                         color: window.location.href.indexOf(item.path) > -1 ?
                             'white'
                             : 'white',
                         fontSize: "initial",
                         fontWeight: "bold",
                         margin: "auto",
                         whiteSpace: "nowrap",
                         marginLeft: 0,
                         paddingRight: '1em',
                         backgroundColor: window.location.href.indexOf(item.path) > -1 ?
                             '#646c9a'
                             : '',
                         borderRadius: "5em"
                     }}
                     to={item.path}
                     onClick={() => this.setActiveItem(item.label)}
                     className={item.label === this.state.active ? 'menu_menu item active' : 'item'}>
                                                <span><img key={index} className="img-icon ml-1"
                                                           src={require(`../../assets/images/dashboard_icons/${item.imgSrc}`)}
                                                           alt=""/></span>
                {item.label}
            </NavLink>

        );
    };

    render() {
        let itemsAdmin = [
            {
                label: "Project Registry",
                imgSrc: 'icons8-registry-editor-50.png',
                path: '/projectRegistry'
            }];
            let itemsAdminPanel = [
            {
                label: "MainPrAreas",
                imgSrc: "faGlobe",
                path: '/mainPrAreas'
            },
            {
                label: "Donors",
                imgSrc: "faDonate",
                path: '/donors'
            },
            {
                label: "Implamenting Organization",
                imgSrc: "faLandmark",
                path: '/implementingOrganizations'
            },
            {
                label: "Sectors",
                imgSrc: "faBezierCurve",
                path: '/sectors'
            },
            {
                label: "Intervention types",
                imgSrc: "faHandHolding",
                path: '/interventions'
            },
            {
                label: "Source Of Financing",
                imgSrc: "faMoneyBillAlt",
                path: '/sourceOfFinancing'
            },
            {
                label: "Users",
                imgSrc: "faUsers",
                path: '/users'
            }
        ];
        let itemsUser = [
            {
                label: "Project Registry",
                imgSrc: 'icons8-registry-editor-50.png',
                path: '/projectRegistry'
            },
        ];

        let items = null;
        if (this.state.userRole === "ROLE_ADMIN") {
            items = itemsAdmin.map(this.itemDisplay);
        }
        if (this.state.userRole === "ROLE_USER") {
            items = itemsUser.map(this.itemDisplay);
        }
        if (this.state.userRole === "ROLE_IMPLEMENTATOR") {
            items = itemsUser.map(this.itemDisplay);
        }

        let userEmail = localStorage.getItem('email');

        return (
            <div>
                <nav className="nav nav-pills navbar-expand-lg navbar navbar-dark bg-dark ">
                    <button type="button" className="navbar-toggler" data-toggle="collapse"
                            data-target="#navbarCollapse1">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse1">
                        <div className="navbar-nav">
                            {items}
                            {this.state.userRole === "ROLE_ADMIN"?
                                <div className='dropdown'>
                                <button className="btn btn-dark dropdown-toggle" type="button"
                                        data-toggle="dropdown">
                                    <FontAwesomeIcon icon={faTools} size="lg" className={"mr-1"}/>
                                    Admin panel
                                </button>

                                <ul className="dropdown-menu dropdown-menu-right" style={{left:"1px",width:"300px"}}>

                                    {itemsAdminPanel.map((item, index) => {

                                        let src = images[item.imgSrc];
                                        ;
                                        return(
                                        <li className='dropdown-item'>
                                            <NavLink key={index}
                                                     style={{
                                                         color: window.location.href.indexOf(item.path) > -1 ?
                                                             'black'
                                                             : 'black',
                                                         fontSize: "initial",
                                                          margin: "auto",
                                                         whiteSpace: "nowrap",
                                                         marginLeft: 0,
                                                         paddingRight: '1em',
                                                         backgroundColor: window.location.href.indexOf(item.path) > -1 ?
                                                             '#646c9a'
                                                             : '',
                                                         borderRadius: "5em"
                                                     }}
                                                     to={item.path}
                                                     onClick={() => this.setActiveItem(item.label)}
                                                     className={item.label === this.state.active ? 'menu_menu item active' : 'item'}>
                                                <FontAwesomeIcon icon={src} className="mr-1"/>
                                                {item.label}
                                            </NavLink>

                                        </li>
                                        )
                                    })
                                    }
                                </ul>

                            </div> : null
                        }
                        </div>

                        <div className="navbar-nav ml-auto">

                        </div>
                        <div className="navbar-nav">
                            <span>

                                <div className='dropdown'>
                                    <button className="btn btn-dark dropdown-toggle" type="button"
                                            data-toggle="dropdown">
                                        <img className="img-icon ml-0"
                                             src={require(`../../assets/images/dashboard_icons/icons8-male-user-50.png`)}
                                             alt=""/>
                                        Profile
                                    </button>

                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li className='dropdown-header'
                                            style={{fontFamily: 'sans-serif'}}
                                        >Logged: {userEmail}</li>


                                        <br/>
                                        <li style={{marginLeft: "15px"}}><FontAwesomeIcon icon={faLock} size="lg"/><a
                                            href="/changePassword" style={{marginLeft: "0px"}}
                                            className='profile-dropdown-link text-center'> Change password</a></li>
                                        <hr/>
                                        <li style={{marginLeft: "5px"}}><a href="/logout"
                                                                           className='profile-dropdown-link ml-0 pl-0'> <img
                                            className="ml-0"
                                            alt=""/><img className="img-icon ml-0"
                                                         src={require(`../../assets/images/dashboard_icons/icons8-sign-out-50.png`)}
                                                         alt=""/>Logout</a></li>
                                    </ul>
                                </div>
                        </span>
                        </div>
                    </div>
                </nav>
            </div>


        );
    }
}

export default Nav;
