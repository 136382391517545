import React, {Component} from 'react';
import '../../assets/css/theme/login-6.css';
import './Login.css';
import AuthenticationService from "../../repository/service/AuthenticationService";
import {AUTH_TOKEN} from "../../shared/utility";
import Background from '../../assets/images/abstract2-bg.jpg'
import axios from "../../axios/app";
import {Link} from 'react-router-dom';


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loginError: null,
            role: "",
            msg: null,
        }
    }

    componentDidMount() {

    }

    fetchRole() {
        axios.get("/rest/users/userDetails").then((data) => {
            localStorage.setItem('email', data.data[0]);
            localStorage.setItem('role', data.data[1]);
            this.props.history.push('/projectRegistry');

        });
    };

    loginHandler(e) {
        e.preventDefault();
        AuthenticationService.loginUser({
            email: this.state.email,
            password: this.state.password
        }).then(response => {
            localStorage.setItem(AUTH_TOKEN, response.data);
            this.clearErrorMessage();
            this.fetchRole();
        }).catch(error => {
            this.setErrorMessage(error.response);
            // alert(error.response.data);
            this.setState({msg: "Invalid email or password"})
        });
    }

    emailChangeHandler(e) {
        this.setState({
            email: e.target.value
        });
    }

    passwordChangeHandler(e) {
        this.setState({
            password: e.target.value
        });
    }

    clearErrorMessage() {
        this.setState({
            loginError: null
        });
    }

    setErrorMessage(statusCode) {
        if (statusCode === 403) {
            this.setState({
                loginError: 'Email or password are incorrect'
            });
        } else {
            this.setState({
                loginError: 'Server temporarily unavailable'
            });
        }
    }

    render() {
        return (
            <div className="kt-grid kt-grid--ver kt-grid--root kt-page kt-grid-page">
                <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin"
                     id="kt_login">
                    <div
                        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div
                            className="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
                            <div className="kt-login__wrapper">
                                <div className="kt-login__container">
                                    <div className="kt-login__body">
                                        <div className="kt-login__logo">
                                            <br/>
                                            <a href="!#">
                                                <img src={require('../../assets/images/MON1.png')}
                                                     className={"img-responsive mtsp-logo"} alt=""/>
                                            </a>
                                        </div>
                                        <div className="kt-login__signin">
                                            <div className="kt-login__head">
                                                <h6 className="portalTitle">Ministry of Education and Science</h6>
                                                <br/>

                                            </div>

                                            <div className="kt-login__form ">
                                                <br/><br/>
                                                <h3 className="kt-login__title justify-content-center d-flex">Login</h3>
                                                <br/><br/>
                                                <form className="kt-form container-fluid" onSubmit={(e) => this.loginHandler(e)}>

                                                    <label htmlFor='email'>Email:</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="email"
                                                               placeholder={" eg. jameswalter@gmail.com"}
                                                               onChange={(e) => this.emailChangeHandler(e)}
                                                               name="email" autoComplete="off"/>
                                                    </div>
                                                    <br/>
                                                    <label htmlFor='password'>Password:</label>
                                                    <div className="form-group">
                                                        <input type="password"
                                                               onChange={(e) => this.passwordChangeHandler(e)}
                                                               className="form-control form-control-last"
                                                               placeholder={" Password"} name="password"/>
                                                    </div>

                                                    {this.state.msg === null ? null : <div
                                                        className="alert mt-2 alert-danger error-msg">{this.state.msg}</div>}

                                                    <div className="kt-login__extra">
                                                        <label className="kt-checkbox">
                                                            <input type="checkbox"
                                                                   name="remember"/> Remember me
                                                            <span></span>
                                                        </label>

                                                    </div>
                                                    <div className="kt-login__actions">
                                                        <div className="container-fluid">
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <button type="submit" id="kt_login_signin_submit"
                                                                            className="btn btn-brand btn-pill btn-elevate">Login
                                                                    </button>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <Link to={'/registerNewUser'}>
                                                                        <button
                                                                            className="btn btn-brand btn-pill btn-elevate">
                                                                            Register
                                                                        </button>
                                                                    </Link>
                                                                    <br/><br/><br/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content"
                            style={{backgroundImage: "url(" + Background + ")"}}>
                            <div className="kt-login__section">
                                <div className="kt-login__block">
                                    <div className="kt-login__desc">
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Login;