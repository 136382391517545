import React, {Component} from 'react';
import ReactPaginate from 'react-paginate';
import InterventionTypesService from '../../repository/service/InterventionTypesService';
import CrudModal from '../Crud/CrudModal';
import EditInterventionType from './EditInterventionType';
import AddNewInterventionType from './AddNewInterventionType';
import {toast} from "react-toastify";

class SectorsTable extends Component{

    constructor(props){
        super(props);

        this.state = {
            interventionTypes : [],
            pageCount: 1
        }
        
    }

    componentDidMount(){
        this.fetchData();
    }

    fetchData = (selectedPage = 0) => {
        InterventionTypesService.getInterventionTypesPaginated(selectedPage).then((resp)=>{

            this.setState({
                interventionTypes: resp.data.content,
                pageCount: resp.data.totalPages
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handlePageClick = (data) => {
        this.fetchData(data.selected);
    }

    addNewIntervention = (newIntervention) =>{
        return InterventionTypesService.addInterventionTypes(newIntervention).then(resp =>{
            this.fetchData();
            toast.success("Successfully added new intervention type.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() =>{
            toast.error("There is a problem adding a new intervention type.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }

    editInterventionType = (newIntervention) =>{
        return InterventionTypesService.editInterventionTypes(newIntervention).then(resp =>{
            this.fetchData();
            toast.success("Successfully edited intervention type.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() =>{
            toast.error("There is a problem editing this intervention type.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }



    renderInterventionTypes = (intervention) =>{
        return (
            <tr key={intervention.id}>
                <td>{intervention.name}</td>
                {this.renderStatus(intervention.status)}
                <td>
                    
                    <CrudModal title='Edit' 
                               modalTitle='Edit intervention type' 
                               entity={intervention} 
                               onSubmit={this.editInterventionType}
                               body={EditInterventionType}
                               btnClass='btn-success btn-sm'
                               />
                </td>
            </tr>
        )
    }

    renderStatus = (statusBoolean) => {
        if(statusBoolean === true)
            return <td>Active</td>
        return <td>Inactive</td>
    }


    
    render(){
        return(
            <div className="container">

                <div className="container pt-4 pb-4">
                    <CrudModal title='Add'
                            modalTitle='Add new intervention type'
                            entity={{}}
                            onSubmit={this.addNewIntervention}
                            body={AddNewInterventionType} 
                            btnClass='btn-primary'/>
                </div>
                

                <table className="table table-hover table-bordered">
                    <thead className='thead-dark'>
                        <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.interventionTypes.map(this.renderInterventionTypes)}
                    </tbody>
                </table>
                <div className="container-fluid">
                <ReactPaginate
                    previousLabel={'prev'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
            </div>
        )
    }
}

export default SectorsTable;