import React, { Component } from 'react';
import ImplOrgService from "../../repository/service/ImplOrgService";
import CrudModal from "../Crud/CrudModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import {toast} from "react-toastify";
import AddImplementators from "./AddImplementators";
import EditImplementators from "./EditImplementators";

class ImplOrg extends Component{

    constructor(props) {
        super(props);
        this.state={
            allOrgs:[],
            pageCount: 0
        }
    }

    componentDidMount() {
        this.fetchData();
    }
    fetchData = (selectedPage = 0) =>{
        ImplOrgService.getImplementatorsPagination(selectedPage).then(res=>{
            this.setState({
                allOrgs:res.data.content,
                pageCount: res.data.totalPages
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handlePageClick = (data) => {
        this.fetchData(data.selected);
    }

    renderOrgs = (implOrg) => {
        return (
            <tr key={implOrg.id}>
                <td>{implOrg.orgName}</td>

                <td>{implOrg.orgAddress ? implOrg.orgAddress:''}</td>
                <td>{implOrg.orgTelephone ? implOrg.orgTelephone : ''}</td>
                <td>{implOrg.orgEmail? implOrg.orgEmail:''}</td>

                <td>{implOrg.status ? "Active" : "Inactive"}</td>
                <td>

                    <CrudModal title='Edit'
                               btnClass="btn-success"
                               entity={implOrg}
                               modalTitle="Edit Implementation Organization"
                               onSubmit={this.editImplementators}
                               body={EditImplementators}
                    />

                </td>
            </tr>
        )
    }

    addImplementators = (implOrg) =>{
        return ImplOrgService.addImplOrg(implOrg).then(() => {
            this.fetchData();
            toast.success("Successfully added new Implementation Organization.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() => {
            toast.error("There is a problem adding a new Implementation Organization.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }
    editImplementators = (implOrg) =>{
        return ImplOrgService.editImplOrg(implOrg).then(resp => {
            this.fetchData();
            toast.success("Successfully updated Implementation Organization.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() => {
            toast.error("There is a problem updating Implementation Organization.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }


    remindOrgs = () => {
        ImplOrgService.remindImplementators().then(() =>{
            toast.success("Successfully sent emails to all active implementing organizations.", {
                position: "top-center",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() =>{
            toast.error("There is a problem sending emails to all active implementing organizations.", {
                position: "top-center",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            })
        })
    }



    render() {
        return(
            <div className={"container"}>
                <br/>
                <div style={{display:"flex"}}>
                    <CrudModal title='Add'
                               entity={{}}
                               modalTitle="Add Implementation Organization"
                               onSubmit={this.addImplementators}
                               body={AddImplementators}
                    />

                    <button className='btn btn-warning btn-md ' style={{ marginLeft: "auto"}} onClick={(this.remindOrgs)}><FontAwesomeIcon icon={faEnvelope} size="lg" style={{paddingRight:'4px'}}/>Remind Organizations</button>
                </div>


                <br/>
                <table className="table table-hover table-bordered">
                    <thead className="thead-dark ">
                    <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Telephone</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.allOrgs.map(this.renderOrgs)}
                    </tbody>
                </table>
                <div className="container-fluid">
                    <ReactPaginate
                        previousLabel={'prev'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        )
    }
}
export default ImplOrg;