import React, {Component} from 'react';
import ProjectRegistryService from '../../repository/service/ProjectRegistryService';
import MainPrAreasService from '../../repository/service/MainPrAreasService';
import SectorsService from '../../repository/service/SectorsService';
import DonorsService from '../../repository/service/DonorsService';
import DatePicker from "react-datepicker";
import UsersService from '../../repository/service/UserService';
import {toast} from "react-toastify";
import MapEntity from "../MapEntity/MapEntity";
import SourceOfFinancingService from "../../repository/service/SourceOfFinancingService";
import MapEntitySourceOfFinancing from "../MapEntitySourceOfFinancing/MapEntitySourceOfFinancing";
import ImplOrgService from "../../repository/service/ImplOrgService";
import ReactTooltip from "react-tooltip";


class AddNewProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentProject: {},

            mainPrAreas: [],
            sectors: [],
            donors: [],
            sourceOfFinancings: [],
            implOrgAll: [],
            startDate: new Date(),
            endDate: new Date(),
            sector: {},
            donor: {},
            romaIntegration: '',
            mainPrArea: {},
            sourceOfFinancing: {},

            currentYear: new Date().getFullYear(),
            otherPrArea: '',
            title: '',
            descr: '',
            totalMonths: 0,
            implementingPartner: '',
            implOrg: {},
            totalAmount: '',
            currency: '',
            currentYearAmount: '',
            nextYearAmount: 0,
            furtherYearsAmount: 0,
            nationalCofinanceAmount: 0,
            comment: '',
            impactDescription: '',


            projectDocumentationData: null,
            projectReportData: null,

            systemObstacles: '',


            errors: {}
        }
    };


    componentDidMount() {
        this.fetchData();
    }


    fetchData = () => {
        MainPrAreasService.getAllPrAreas().then((resp) => {
            this.setState({
                mainPrAreas: resp.data
            })
        })

        SectorsService.getAllSectors().then((resp) => {
            this.setState({
                sectors: resp.data
            })
        })

        DonorsService.getAllDonors().then((resp) => {
            this.setState({
                donors: resp.data
            })
        })

        SourceOfFinancingService.getAllSourceOfFinancing().then((resp) => {
            this.setState({
                sourceOfFinancings: resp.data
            })
        })


        ImplOrgService.getAllActiveImplementators().then((resp) => {
            this.setState({implOrgAll: resp.data})
        })

        if (localStorage.getItem('role') === 'ROLE_IMPLEMENTATOR') {
            UsersService.getImplementatorFromLoggedUser().then((resp) => {
                this.setState({
                    implOrg: resp.data
                })
            })
        }
    }


    // RENDERING

    renderMainPrAreas = (area) => {
        return <option key={area.id} value={area.id}>{area.name}</option>
    }

    renderSectors = (sector) => {
        return <option key={sector.id} value={sector.id}>{sector.name}</option>
    }
    renderDonors = (donor) => {
        return <option key={donor.id} value={donor.id}>{donor.name}</option>
    }
    renderImplOrg = (implOrg) => {
        return <option key={implOrg.id} value={implOrg.id}>{implOrg.orgName}</option>
    }

    renderSourceOfFinancings = (sourceOfFinancing) => {
        return <option key={sourceOfFinancing.id} value={sourceOfFinancing.id}>{sourceOfFinancing.name}</option>
    }

    renderDonorsDropdown = () => {

        return (
            <div>
                <label htmlFor='donor' className=''><span style={{color:"red"}}>*</span>Donor:</label>
                <select name='donor' className='form-control custom-select' onChange={this.handleDonorChange}>
                    <option>Select donor</option>
                    {this.state.donors.map(this.renderDonors)}
                </select>
            </div>
        )

    }

    renderImplOrgDropdown = () => {
        if (localStorage.getItem('role') === 'ROLE_IMPLEMENTATOR') {
            return (
                <div></div>
            )

        } else {
            return (
                <div>
                    <label htmlFor='implOrg' className=''>Implementing Organization:</label>
                    <select name='implOrg' className='form-control custom-select' onChange={this.handleImplOrgChange}>
                        <option>Select Implementing Organization</option>
                        {this.state.implOrgAll.map(this.renderImplOrg)}
                    </select>
                </div>
            )
        }
    }

    renderProjDocumentationUpload = () => {
        return (
            <div>
                <label>Project documentation:</label>
                <br/>
                <input type="file" name='projectDocumentation'
                       onChange={this.onProjDocumentationChange}/>
                {this.state.errors.projectDocumentation &&
                <div className="alert alert-danger error-msg">{this.state.errors.projectDocumentation}</div>}
                <br/><br/>
            </div>
        )
    }
    renderProjReportUpload = () => {
        return (
            <div>
                <label>Project report:</label>
                <br/>
                <input type="file" name='projectReport'
                       onChange={this.onProjReportChange}/>
                {this.state.errors.projectReport &&
                <div className="alert alert-danger error-msg">{this.state.errors.projectReport}</div>}
                <br/><br/><br/>
            </div>
        )
    }
    renderImpactDescriptionField = () => {
        if (this.state.romaIntegration === 'Select Roma integration' ||
            this.state.romaIntegration === 'NO_IMPACT' || this.state.romaIntegration === '') {
            return (
                <div className='form-group'>
                    <label htmlFor='impactDescription'>Impact description: </label>
                    <br/>
                    <input type='text' id='impactDescription' name='impactDescription'
                           className='form-control'
                           onChange={this.handleImpactDescriptionChange}
                           readOnly/>
                    {this.state.errors.impactDescription &&
                    <div className="alert alert-danger error-msg">{this.state.errors.impactDescription}</div>}
                    <br/>
                </div>
            )
        } else {
            return (
                <div className='form-group'>
                    <label htmlFor='impactDescription'>Impact description: </label>
                    <br/>
                    <input type='text' id='impactDescription' name='impactDescription'
                           className='form-control'
                           onChange={this.handleImpactDescriptionChange}
                    />
                    {this.state.errors.impactDescription &&
                    <div className="alert alert-danger error-msg">{this.state.errors.impactDescription}</div>}
                    <br/>
                </div>
            )
        }
    }


    // FORM SUBMIT

    onFormSubmit = (formData) => {
        formData.preventDefault();

        const errors = this.validation();
        this.setState({errors: errors || {}});
        if (errors) return;


        const newProject = {
            sector: this.state.sector,
            romaIntegration: this.state.romaIntegration,
            mainPrAreas: this.state.mainPrArea,
            otherPrArea: this.state.otherPrArea,

            title: this.state.title,
            descr: this.state.descr,

            startDate: this.state.startDate,
            endDate: this.state.endDate,
            totalMonths: this.state.totalMonths,


            donor: this.state.donor,
            implementingPartner: this.state.implementingPartner,
            totalAmount: this.state.totalAmount,
            currency: this.state.currency,
            nextYearAmount: this.state.nextYearAmount,
            currentYearAmount: this.state.currentYearAmount,
            furtherYearsAmount: this.state.furtherYearsAmount,
            nationalCofinanceAmount: this.state.nationalCofinanceAmount,
            comment: this.state.comment,
            currentYear: this.state.currentYear,

            impactDescription: this.state.impactDescription,

            systemObstacles: this.state.systemObstacles
        }


        const frmData = new FormData();
        if (this.state.interventions !== undefined)
            frmData.append("interventionsIds", this.state.interventions);
        else frmData.append("interventionsIds", []);

        if (this.state.sourceOfFinancings !== undefined)
            frmData.append("sourceOfFinancingId", this.state.sourceOfFinancings);
        else frmData.append("sourceOfFinancingId", []);


        frmData.append("implOrg", this.state.implOrg.id)
        frmData.append('projectDocumentationData', this.state.projectDocumentationData)
        frmData.append('projectReportData', this.state.projectReportData)


        ProjectRegistryService.addNewProject(newProject, frmData).then(resp => {
            this.props.history.push("/projectRegistry");
            toast.success("Successfully added project.", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "success",

            })
        }).catch(() => {
            toast.error("There is a problem adding a new project.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                type: "error",

            });
        })
    }


    // INPUT HANDLING


    onProjDocumentationChange = (event) => {
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(event); // should be application/pdf
        if (errorMessage)
            errors['projectDocumentation'] = errorMessage;
        else
            delete errors['projectDocumentation'];

        this.setState({errors: errors || {}});

        this.setState({projectDocumentationData: event.target.files[0]});
    };

    onProjReportChange = (event) => {
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(event); // should be application/pdf
        if (errorMessage)
            errors['projectReport'] = errorMessage;
        else
            delete errors['projectReport'];

        this.setState({errors: errors || {}});

        this.setState({projectReportData: event.target.files[0]});
    };

    handleStartDateChange = date => {

        const errors = {...this.state.errors};
        if (date == null) {
            const errorMessage = 'Start date is required'
            errors['startDate'] = errorMessage;
        } else {
            delete errors['startDate'];
        }
        this.setState({errors: errors || {}});

        this.setState({
            startDate: date
        }, () => {
            let totalMonths = this.monthDiff(this.state.startDate, this.state.endDate)
            this.setState({
                totalMonths: totalMonths
            })
        });
    };
    monthDiff = (d1, d2) => {
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }
    handleEndDateChange = date => {

        const errors = {...this.state.errors};
        if (date == null) {
            const errorMessage = 'End date is required'
            errors['endDate'] = errorMessage;
        } else {
            delete errors['endDate'];
        }
        this.setState({errors: errors || {}});

        this.setState({
            endDate: date
        }, () => {
            let totalMonths = this.monthDiff(this.state.startDate, this.state.endDate)
            this.setState({
                totalMonths: totalMonths
            })
        });
    };

    handleSectorChange = (e) => {

        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['sector'] = errorMessage;
        else
            delete errors['sector'];

        this.setState({errors: errors || {}});

        if (e.target.value === "Select sector") {
            this.setState({sector: {}});
        } else {
            SectorsService.getSectorById(e.target.value).then((resp) => {

                this.setState({
                    sector: resp.data,
                })

            })
        }
    }


    handleRomaIntegrationChange = (e) => {
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['romaIntegration'] = errorMessage;
        else
            delete errors['romaIntegration'];

        this.setState({errors: errors || {}});

        this.setState({romaIntegration: e.target.value});
    }

    handleImpactDescriptionChange = (e) => {
        if (e !== undefined) {
            const errors = {...this.state.errors};
            const errorMessage = this.validateProperty(e);
            if (errorMessage)
                errors['impactDescription'] = errorMessage;
            else
                delete errors['impactDescription'];
            this.setState({errors: errors || {}});

            this.setState({impactDescription: e.target.value});
        }

    }


    handleDonorChange = (e) => {

        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['donor'] = errorMessage;
        else
            delete errors['donor'];

        this.setState({errors: errors || {}});

        if (e.target.value === "Select donor") {
            this.setState({donor: {}});
        } else {

            DonorsService.getDonorById(e.target.value).then((resp) => {

                this.setState({
                    donor: resp.data
                })
            })
        }
    }

    handleMainPrAreaChange = (e) => {

        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['mainPrArea'] = errorMessage;
        else
            delete errors['mainPrArea'];

        this.setState({errors: errors || {}});

        if (e.target.value === "Select main pr area") {
            this.setState({mainPrArea: {}});
        } else {
            MainPrAreasService.getMainPrAreaById(e.target.value).then((resp) => {

                this.setState({
                    mainPrArea: resp.data
                })

            })
        }

    }


    onSelectedSourceOfFinancingChange = (e) => {
        this.setState({sourceOfFinancings: e})
    }

    onSelectedInterventionChange = (e) => {
        this.setState({interventions: e})
    }


    handleTitleChange = (e) => {
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['title'] = errorMessage;
        else
            delete errors['title'];

        this.setState({
            title: e.target.value
        })

        this.setState({errors: errors || {}});
    };


    handleDescrChange = (e) => {
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['descr'] = errorMessage;
        else
            delete errors['descr'];

        this.setState({
            descr: e.target.value
        })

        this.setState({errors: errors || {}});
    };


    handleTotalMonthsChange = (e) => {

        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage) {
            errors['totalMonths'] = errorMessage;
        } else {
            delete errors['totalMonths'];
        }

        this.setState({
            totalMonths: e.target.value
        });

        this.setState({errors: errors || {}});
    }


    handleImplementingPartnerChange = (e) => {
        this.setState({
            implementingPartner: e.target.value
        })
    };


    handleImplOrgChange = (e) => {
        if (e.target.value === "Select donor") {
            this.setState({implOrg: {}});
        } else {

            ImplOrgService.getImplOrgId(e.target.value).then((resp) => {

                this.setState({
                    implOrg: resp.data
                })
            })
        }

        this.setState({
            implOrg: e.target.value
        })

    };


    handleTotalAmountChange = (e) => {

        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['totalAmount'] = errorMessage;
        else
            delete errors['totalAmount'];

        this.setState({
            totalAmount: e.target.value
        })

        this.setState({errors: errors || {}});
    };

    handleCurrencyChange = (e) => {

        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['currency'] = errorMessage;
        else
            delete errors['currency'];

        this.setState({
            currency: e.target.value
        })

        this.setState({errors: errors || {}});
    };


    handleNextYearAmountChange = (e) => {
        this.setState({
            nextYearAmount: e.target.value
        })
    };


    handleCurrentYearAmountChange = (e) => {
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['currentYearAmount'] = errorMessage;
        else
            delete errors['currentYearAmount'];

        this.setState({
            currentYearAmount: e.target.value
        })

        this.setState({errors: errors || {}});
    };


    handleNationalCofinanceAmountChange = (e) => {

        this.setState({
            nationalCofinanceAmount: e.target.value
        })
    };


    handleFurtherYearsAmountChange = (e) => {
        this.setState({
            furtherYearsAmount: e.target.value
        })
    };


    handleCommentChange = (e) => {
        this.setState({
            comment: e.target.value
        })
    };
    handleSystemObstaclesChange = (e) => {

        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['systemObstacles'] = errorMessage;
        else
            delete errors['systemObstacles'];
        this.setState({
            systemObstacles: e.target.value
        })

        this.setState({errors: errors || {}});
    }

    // VALIDATION

    validation = () => {
        const errors = {};

        if ((Date.parse(this.state.startDate)) > (Date.parse(this.state.endDate)))
            errors.endDate = 'End date must be after start date!'

        if (Object.keys(this.state.sector).length === 0 && this.state.sector.constructor === Object)
            errors.sector = 'Sector is required!'

        if (Object.keys(this.state.mainPrArea).length === 0 && this.state.mainPrArea.constructor === Object)
            errors.mainPrArea = 'Main Pr Area is required!'

        if (Object.keys(this.state.donor).length === 0 && this.state.donor.constructor === Object)
            errors.donor = 'Donor is required!'
        if (this.state.romaIntegration === '' || this.state.romaIntegration === undefined)
            errors.romaIntegration = 'Roma integration is required!'
        if (this.state.romaIntegration === 'DIRECT_IMPACT' || this.state.romaIntegration === 'INDIRECT_IMPACT') {
            if (this.state.impactDescription === '') {
                errors.impactDescription = 'Impact description is required when roma integration is direct or indirect impact!'
            }
        }

        if (this.state.title === '' || this.state.title === undefined)
            errors.title = 'Title is required!'
        if (this.state.descr === '' || this.state.descr === undefined)
            errors.description = 'Description is required!'
        if (this.state.totalMonths === '' || this.state.totalMonths === undefined)
            errors.totalMonths = 'Total months is required!'
        if (parseInt(this.state.totalMonths) <= 0) {
            errors.totalMonths = 'Total months must be > 0!'
        }

        if (this.state.totalAmount === '' || this.state.totalAmount === undefined)
            errors.totalAmount = 'Total amount is required!'
        if (parseInt(this.state.totalAmount) < 0)
            errors.totalAmount = 'Total amount must be >= 0!'
        if (this.state.currency === '' || this.state.currency === undefined)
            errors.currency = 'Currency is required!'
        if (this.state.currentYearAmount === '' || this.state.currentYearAmount === undefined)
            errors.currentYearAmount = 'Current year amount is required!'
        if (parseInt(this.state.currentYearAmount) < 0)
            errors.currentYearAmount = 'Current year amount must be >= 0!'
        if (this.state.projectDocumentationData != null) {
            if (this.state.projectDocumentationData.type !== 'application/pdf')
                errors.projectDocumentation = 'Project documentation must be in pdf format!'
        }
        if (this.state.projectReportData != null) {
            if (this.state.projectReportData.type !== 'application/pdf')
                errors.projectReport = 'Project report must be in pdf format!'
        }


        return Object.keys(errors).length === 0 ? null : errors;
    }

    validateProperty = (e) => {
        if (e.target.name === 'projectDocumentation') {
            if (e.target.files[0] !== undefined) {
                if (e.target.files[0].type !== 'application/pdf')
                    return 'Project documentation must be in pdf format!'
            }
        }
        if (e.target.name === 'projectReport') {
            if (e.target.files[0] !== undefined) {
                if (e.target.files[0].type !== 'application/pdf')
                    return 'Project report must be in pdf format!'
            }
        }

        if (e.target.name === 'sector') {
            if (e.target.value === 'Select sector' || e.target.value === undefined)

                return 'Sector is required!';
        }
        if (e.target.name === 'mainPrArea') {
            if (e.target.value === 'Select main pr area' || e.target.value === undefined)
                return 'Main Pr Area is required!';
        }

        if (e.target.name === 'donor') {
            if (e.target.value === 'Select donor' || e.target.value === undefined)
                return 'Donor is required!';
        }
        if (e.target.name === 'romaIntegration') {
            if (e.target.value === 'Select Roma integration' || e.target.value === undefined)
                return 'Roma Integration is required!';
        }
        if (e.target.name === 'impactDescription') {
            if (this.state.romaIntegration === 'DIRECT_IMPACT' || this.state.romaIntegration === 'INDIRECT_IMPACT') {
                if (e.target.value === '')
                    return 'Impact description is required when roma integration is direct or indirect impact!'
            }
        }
        if (e.target.name === 'title') {
            if (e.target.value === '' || e.target.value === undefined) return 'Title is required!';
        }
        if (e.target.name === 'description') {
            if (e.target.value === '' || e.target.value === undefined) return 'Description is required!';
        }
        if (e.target.name === 'totalMonths') {
            if (e.target.value === '' || e.target.value === undefined) return 'Total months is required!';
        }
        if (e.target.name === 'totalMonths') {
            if (parseInt(e.target.value) <= 0) return 'Total months must be > 0!';
        }

        if (e.target.name === 'totalAmount') {
            if (e.target.value === '' || e.target.value === undefined) return 'Total amount is required!';
        }
        if (e.target.name === 'totalAmount') {
            if (parseInt(e.target.value) < 0) return 'Total amount must be >= 0!';
        }
        if (e.target.name === 'currency') {
            if (e.target.value === '' || e.target.value === undefined) return 'Currency is required!';
        }
        if (e.target.name === 'currentYearAmount') {
            if (e.target.value === '' || e.target.value === undefined) return 'Current year amount is required!';
        }
        if (e.target.name === 'currentYearAmount') {
            if (parseInt(e.target.value) < 0) return 'Current year amount must be >= 0!';
        }

    }


    onResetButtonClick = () => {
        this.setState({
            errors: {}
        })
    }


    render() {

        return (
            <div className='container'>
                <form className='form mb-5' onSubmit={this.onFormSubmit}>
                    <br/>
                    <label htmlFor='sector' className=''><span style={{color:"red"}}>*</span>Sector:</label>
                    <select name='sector' className='form-control custom-select'
                            onChange={this.handleSectorChange}>
                        <option>Select sector</option>
                        {this.state.sectors.map(this.renderSectors)}
                    </select>

                    {this.state.errors.sector &&
                    <div className="alert alert-danger error-msg">{this.state.errors.sector}</div>}
                    <br/>

                    <label htmlFor='roma_integration' className=''>Roma integration:</label>
                    <select name='romaIntegration' className='form-control custom-select'
                            onChange={this.handleRomaIntegrationChange}>
                        <option>Select Roma integration</option>
                        <option value='NO_IMPACT'>No impact</option>
                        <option value='INDIRECT_IMPACT'>Indirect impact</option>
                        <option value='DIRECT_IMPACT'>Direct impact</option>
                    </select>
                    {this.state.errors.romaIntegration &&
                    <div className="alert alert-danger error-msg">{this.state.errors.romaIntegration}</div>}
                    <br/>

                    {this.renderImpactDescriptionField()}


                    <label htmlFor='main_pr_area' className=''><span style={{color:"red"}}>*</span>Main PR area:</label>
                    <select name='mainPrArea' className='form-control custom-select'
                            onChange={this.handleMainPrAreaChange}>
                        <option>Select main pr area</option>
                        {this.state.mainPrAreas.map(this.renderMainPrAreas)}
                    </select>

                    {this.state.errors.mainPrArea &&
                    <div className="alert alert-danger error-msg">{this.state.errors.mainPrArea}</div>}
                    <br/>
                    <label htmlFor='other_pr_area' className=''>Other pr area:</label>
                    {this.state.mainPrArea.name === "Other" ?
                        <input type='text' id='other_pr_area' name='other_pr_area' className='form-control'
                               onChange={this.handleOtherPrAreaChange}/> :
                        <input type='text' disabled={true} id='other_pr_area' name='other_pr_area'
                               className='form-control'

                               onChange={this.handleOtherPrAreaChange}/>}
                    {this.state.errors.otherPrArea &&
                    <div className="alert alert-danger error-msg">{this.state.errors.otherPrArea}</div>}
                    <br/>
                    <label htmlFor='title' className=''><span style={{color:"red"}}>*</span>Title:</label>
                    <input type='text' id='title' name='title' className='form-control'
                           onChange={this.handleTitleChange}/>
                    {this.state.errors.title &&
                    <div className="alert alert-danger error-msg">{this.state.errors.title}</div>}
                    <br/>

                    <label htmlFor='description' className=''><span style={{color:"red"}}>*</span>Description:</label>
                    <textarea id='description' name='description' className='form-control'
                              onChange={this.handleDescrChange}/>
                    {this.state.errors.description &&
                    <div className="alert alert-danger error-msg">{this.state.errors.description}</div>}


                    <br/>
                    <label htmlFor='start_date' className=''><span style={{color:"red"}}>*</span>Start date:</label><br/>
                    <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleStartDateChange}
                        className="form-control"
                        name='start_date'
                        dateFormat="dd-MM-yyyy"
                    />
                    {this.state.errors.startDate &&
                    <div className="alert alert-danger error-msg">{this.state.errors.startDate}</div>}

                    <br/><br/>
                    <label htmlFor='end_date' className=''><span style={{color:"red"}}>*</span>End date:</label><br/>
                    <DatePicker
                        selected={this.state.endDate}
                        onChange={this.handleEndDateChange}
                        className="form-control"
                        name='end_date'
                        dateFormat="dd-MM-yyyy"
                    />
                    {this.state.errors.endDate &&
                    <div className="alert alert-danger error-msg">{this.state.errors.endDate}</div>}

                    <br/>
                    <br/>

                    <label htmlFor='total_months' className=''><span style={{color:"red"}}>*</span>Total months:</label>
                    <input type='number' id='total_months' name='totalMonths' value={this.state.totalMonths}
                           className='form-control'
                           onChange={this.handleTotalMonthsChange}/>
                    {this.state.errors.totalMonths &&
                    <div className="alert alert-danger error-msg">{this.state.errors.totalMonths}</div>}
                    <br/>

                    {this.renderDonorsDropdown()}


                    {this.state.errors.donor &&
                    <div className="alert alert-danger error-msg">{this.state.errors.donor}</div>}
                    <br/>

                    <label htmlFor='sourceOfFinancing' className=''><span style={{color:"red"}}>*</span>Source Of Financing:</label>
                    <MapEntitySourceOfFinancing
                        groupsUrl="rest/sourceOfFinancing/all"
                        onChange={this.onSelectedSourceOfFinancingChange}/>

                    {this.state.errors.sourceOfFinancing &&
                    <div className="alert alert-danger error-msg">{this.state.errors.sourceOfFinancing}</div>}
                    <br/>
                    {this.renderImplOrgDropdown()}
                    <br/>
                    <label htmlFor='impl_partner' className=''>Implementing partner:</label>
                    <input type='text' id='impl_partner' name='implementingPartner' className='form-control'
                           onChange={this.handleImplementingPartnerChange}/>

                    <br/>

                    <label htmlFor='total_amount' className=''><span style={{color:"red"}}>*</span>Total amount: &nbsp;<i className="fa fa-question-circle"
                                                                                                                          aria-hidden="true"
                                                                                                                          data-tip="Please DO NOT enter numbers with 0 after the decimal point, (Example not like this: 10.0000)."
                                                                                                                          data-for='toolTip1' data-place='right'></i>
                        <ReactTooltip id="toolTip1" /></label>
                    <input type='number' id='total_amount' name='totalAmount' className='form-control'
                           onChange={this.handleTotalAmountChange}/>
                    {this.state.errors.totalAmount &&
                    <div className="alert alert-danger error-msg">{this.state.errors.totalAmount}</div>}
                    <br/>
                    <label htmlFor='currency' className=''><span style={{color:"red"}}>*</span>Select Currency:</label>
                    <select name='currency' className='form-control custom-select'
                            onChange={this.handleCurrencyChange}>

                        <option>-Select Currency-</option>
                        <option>EUR</option>
                        <option>USD</option>
                        <option>MKD</option>
                    </select>
                    {this.state.errors.currency &&
                    <div className="alert alert-danger error-msg">{this.state.errors.currency}</div>}
                    <br/>
                    <label htmlFor='current_year_amount' className=''><span style={{color:"red"}}>*</span>Current year amount: &nbsp;<i className="fa fa-question-circle"
                                                                                                                                        aria-hidden="true"
                                                                                                                                        data-tip="Please DO NOT enter numbers with 0 after the decimal point, (Example not like this: 10.0000)."
                                                                                                                                        data-for='toolTip2' data-place='right'></i>
                        <ReactTooltip id="toolTip2" /></label>
                    <input type='number' id='current_year_amount' name='currentYearAmount' className='form-control'
                           onChange={this.handleCurrentYearAmountChange}/>
                    {this.state.errors.currentYearAmount &&
                    <div className="alert alert-danger error-msg">{this.state.errors.currentYearAmount}</div>}
                    <br/>
                    <label htmlFor='next_year_amount' className=''>Next year amount: &nbsp;<i className="fa fa-question-circle"
                                                                                              aria-hidden="true"
                                                                                              data-tip="Please DO NOT enter numbers with 0 after the decimal point, (Example not like this: 10.0000)."
                                                                                              data-for='toolTip3' data-place='right'></i>
                        <ReactTooltip id="toolTip3" /></label>
                    <input type='number' id='next_year_amount' name='nextYearAmount' className='form-control'
                           onChange={this.handleNextYearAmountChange}/>
                    <br/>

                    <label htmlFor='further_years_amount' className=''>Further years amount: &nbsp;<i className="fa fa-question-circle"
                                                                                                      aria-hidden="true"
                                                                                                      data-tip="Please DO NOT enter numbers with 0 after the decimal point, (Example not like this: 10.0000)."
                                                                                                      data-for='toolTip4' data-place='right'></i>
                        <ReactTooltip id="toolTip4" /></label>
                    <input type='number' id='further_years_amount' name='furtherYearsAmount'
                           className='form-control'
                           onChange={this.handleFurtherYearsAmountChange}/>
                    <br/>
                    <label htmlFor='national_cof_amount' className=''>National cofinance amount: &nbsp;<i className="fa fa-question-circle"
                                                                                                          aria-hidden="true"
                                                                                                          data-tip="Please DO NOT enter numbers with 0 after the decimal point, (Example not like this: 10.0000)."
                                                                                                          data-for='toolTip5' data-place='right'></i>
                        <ReactTooltip id="toolTip5" /></label>
                    <input type='number' id='national_cof_amount' name='nationalCofinanceAmount'
                           className='form-control'
                           onChange={this.handleNationalCofinanceAmountChange}/>
                    <br/>
                    <label htmlFor='comment' className=''>Comment:</label>
                    <textarea id='comment' name='comment' className='form-control'
                              onChange={this.handleCommentChange}/>

                    <br/>

                    {this.renderProjDocumentationUpload()}
                    {this.renderProjReportUpload()}

                    <label>Interventions types:</label>
                    <MapEntity
                        groupsUrl="/rest/interventions/all" //za da gi zemem tagovite ili kategorija
                        onChange={this.onSelectedInterventionChange}/>
                    <br/>


                    <label htmlFor='systemObstacles' className=''>System Obstacles: &nbsp;<i className="fa fa-question-circle"
                                                                                             aria-hidden="true"
                                                                                             data-tip="Should you have experienced any obstacles or difficulties during the project realization considering the budget allocation or the project deadlines, please elaborate here."
                                                                                             data-for='toolTip6' data-place='right'></i>
                        <ReactTooltip id="toolTip6" /></label>
                    <textarea id='systemObstacles' name='systemObstacles' className='form-control'
                              onChange={this.handleSystemObstaclesChange}/>


                    <br/>
                    <button type='submit' className='btn btn-primary project-registry-form-btn'>Submit</button>
                    <button type='reset' className='btn btn-danger project-registry-form-btn'
                            onClick={() => {
                                this.onResetButtonClick()
                            }}>Reset
                    </button>

                    <button className='btn btn-dark' onClick={() => {
                        this.props.history.push('/projectRegistry')
                    }}>
                        Cancel
                    </button>

                </form>
            </div>
        )
    }
}


export default AddNewProject;