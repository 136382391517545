import axios from '../../axios/app';

const ImplOrgService = {

    getImplementatorsPagination: (numPage) => {
        return axios.get(`/rest/implOrg/pageable?page=${numPage}&size=10`)
    },
    getAllActiveImplementators: () => {
        return axios.get('/rest/implOrg/all');
    },
    addImplOrg: (implOrg) =>
    {
        return axios.post(`/rest/implOrg/create`, implOrg)
    },
    editImplOrg: (implOrg) => {
        return axios.patch(`/rest/implOrg/update`, implOrg)
    },
    getImplOrgId: (id) => {
        return axios.get(`/rest/implOrg/${id}`);
    },
    remindImplementators: () => {
        return axios.get('/rest/implOrg/remindImplementators');
    }


};
export default ImplOrgService;