import React, {Component} from "react";


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {year: new Date().getFullYear()}
    }

    render() {
        return (
            <footer className="page-footer">

                <div className="footer-copyright text-center py-3">© 2020 Copyright&nbsp;
                   <strong>MON</strong>
                </div>

            </footer>
        );
    }

}

export default Footer;