import React, { Component } from 'react';
import MainPrAreasService from '../../repository/service/MainPrAreasService';
import SectorsService from '../../repository/service/SectorsService';
import DonorsService from '../../repository/service/DonorsService';
import ProjectRegistryService from '../../repository/service/ProjectRegistryService';
import NumberFormat from 'react-number-format';



class ReportsTable extends Component{

    constructor(props){
        super(props);
        this.state = {
            mainPrAreas: [],
            sectors: [],
            donors: [],

            reports: [],

            MKDtoEUR: 0.016,
            USDtoEUR: 1.17,
            sectorId: null,
            donorId: null,
            mainPrAreaId: null,

            errors: {}
        }
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = () =>{
        MainPrAreasService.getAllPrAreas().then((resp) => {
            this.setState({
                mainPrAreas: resp.data
            })
        })

        SectorsService.getAllSectors().then((resp) => {
            this.setState({
                sectors: resp.data
            })
        })

        DonorsService.getAllDonors().then((resp) => {
            this.setState({
                donors: resp.data
            })
        })

        ProjectRegistryService.getDefaultReport(this.state.MKDtoEUR, this.state.USDtoEUR).then((resp) => {
            this.setState({
                reports: resp.data
            })
        })

    }


    handleSectorChange = (e) => {

        if (e.target.value === "Select sector") {
            this.setState({sectorId: null}, () => {
                if(Object.keys(this.state.errors).length === 0)
                    this.generateFilteredReport();
            });
        }
        else {
            this.setState({
                sectorId: e.target.value
            }, () => {
                if(Object.keys(this.state.errors).length === 0)
                    this.generateFilteredReport();
            })
        }      
    };

    handleDonorChange = (e) => {

        if (e.target.value === "Select donor") {
            this.setState({donorId: null}, () => {
                if(Object.keys(this.state.errors).length === 0)
                    this.generateFilteredReport();
            });
        }
        else {
            this.setState({
                donorId: e.target.value
            }, () => {
                if(Object.keys(this.state.errors).length === 0)
                    this.generateFilteredReport();
            })       
        }
    };

    handleMainPrAreaChange = (e) => {

        if (e.target.value === "Select main pr area") {
            this.setState({mainPrAreaId: null}, () => {
                if(Object.keys(this.state.errors).length === 0)
                    this.generateFilteredReport();
            });
        }
        else{
            this.setState({
                mainPrAreaId: e.target.value
            }, () => {
                if(Object.keys(this.state.errors).length === 0)
                    this.generateFilteredReport();
            })
        }

    };

    

    generateFilteredReport = () =>{
        if(this.state.sectorId === null && this.state.donorId === null && this.state.mainPrAreaId === null){
            this.generateDefaultReport();
        } else if(this.state.sectorId !== null && this.state.donorId === null && this.state.mainPrAreaId === null){     // sec not null
            ProjectRegistryService.getReport(this.state.sectorId, this.state.donorId, this.state.mainPrAreaId,
                    this.state.MKDtoEUR, this.state.USDtoEUR, 'Sector').then((resp) => {
                        this.setState({
                            reports: resp.data
                        })
                    })
        } else if(this.state.sectorId === null && this.state.donorId !== null && this.state.mainPrAreaId === null){     // dnr not null
            ProjectRegistryService.getReport(this.state.sectorId, this.state.donorId, this.state.mainPrAreaId,
                this.state.MKDtoEUR, this.state.USDtoEUR, 'Donor').then((resp) => {
                    this.setState({
                        reports: resp.data
                    })
                })
        } else if(this.state.sectorId === null && this.state.donorId === null && this.state.mainPrAreaId !== null){     // area not null
            ProjectRegistryService.getReport(this.state.sectorId, this.state.donorId, this.state.mainPrAreaId,
                this.state.MKDtoEUR, this.state.USDtoEUR, 'Area').then((resp) => {
                    this.setState({
                        reports: resp.data
                    })
                })
        } else if(this.state.sectorId !== null && this.state.donorId !== null && this.state.mainPrAreaId === null){     // sec, dnr not null
            ProjectRegistryService.getReport(this.state.sectorId, this.state.donorId, this.state.mainPrAreaId,
                this.state.MKDtoEUR, this.state.USDtoEUR, 'SectorAndDonor').then((resp) => {
                    this.setState({
                        reports: resp.data
                    })
                })
        } else if(this.state.sectorId !== null && this.state.donorId === null && this.state.mainPrAreaId !== null){     // sec, area not null
            ProjectRegistryService.getReport(this.state.sectorId, this.state.donorId, this.state.mainPrAreaId,
                this.state.MKDtoEUR, this.state.USDtoEUR, 'SectorAndArea').then((resp) => {
                    this.setState({
                        reports: resp.data
                    })
                })
        } else if(this.state.sectorId === null && this.state.donorId !== null && this.state.mainPrAreaId !== null){     // dnr, area not null
            ProjectRegistryService.getReport(this.state.sectorId, this.state.donorId, this.state.mainPrAreaId,
                this.state.MKDtoEUR, this.state.USDtoEUR, 'DonorAndArea').then((resp) => {
                    this.setState({
                        reports: resp.data
                    })
                })
        } else {        // all not null
            ProjectRegistryService.getReport(this.state.sectorId, this.state.donorId, this.state.mainPrAreaId,
                this.state.MKDtoEUR, this.state.USDtoEUR, 'SectorAndDonorAndArea').then((resp) => {
                    this.setState({
                        reports: resp.data
                    })
                })
        }

    }

    generateDefaultReport = () => {
        ProjectRegistryService.getDefaultReport(this.state.MKDtoEUR, this.state.USDtoEUR).then((resp) => {
            this.setState({
                reports: resp.data
            })
        })
    }


    handleMkdToEurChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['MKDtoEUR'] = errorMessage;
        else{
            delete errors['MKDtoEUR'];

            this.setState({
                MKDtoEUR: e.target.value
            }, () => {
                this.generateFilteredReport();
            })
            
        }
            
        this.setState({errors: errors || {}});           
    }
    handleUsdToEurChange = (e) => {
        e.preventDefault();
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e);
        if (errorMessage)
            errors['USDtoEUR'] = errorMessage;
        else{
            delete errors['USDtoEUR'];

            this.setState({
                USDtoEUR: e.target.value
            }, () => {
                this.generateFilteredReport();
            })

        }
            
        this.setState({errors: errors || {}});   
    }



    validateProperty = (e) => {
        if (e.target.name === 'MKDtoEUR') {
            if (parseFloat(e.target.value) < 0) return 'Must be > 0!';
        }
        if (e.target.name === 'USDtoEUR') {
            if (parseFloat(e.target.value) < 0) return 'Must be > 0!';
        }
    }




    renderMainPrAreas = (area) => {
        return <option key={area.id} value={area.id}>{area.name}</option>
    };
    renderSectors = (sector) => {
        return <option key={sector.id} value={sector.id}>{sector.name}</option>
    };
    renderDonors = (donor) => {
        return <option key={donor.id} value={donor.id}>{donor.name}</option>
    };


    render() {
        let totalAmountSum=0
        let currentYearAmountSum=0
        let nextYearAmountSum=0
        let furtherYearsAmountSum=0
        let nationalCofinanceAmountSum=0
        return(
            <div className='ml-5'>

                <div className='row mt-2'>
                    <div className='col-3'>
                        <label htmlFor='MKDtoEUR'>Exchange rate MKD to EUR:</label>                    
                        <input type='number' name='MKDtoEUR' className='form-control' 
                            onChange={this.handleMkdToEurChange} defaultValue={this.state.MKDtoEUR}/>
                        {this.state.errors.MKDtoEUR &&
                        <div className=" alert-danger error-msg-currency">{this.state.errors.MKDtoEUR}</div>}
                    </div>
                    <div className='col-3'>
                        <label htmlFor='USDtoEUR'>Exchange rate USD to EUR:</label>                     
                        <input type='number' name='USDtoEUR'className='form-control' 
                            onChange={this.handleUsdToEurChange} defaultValue={this.state.USDtoEUR}/>   
                        {this.state.errors.USDtoEUR &&
                        <div className=" alert-danger error-msg-currency">{this.state.errors.USDtoEUR}</div>}                         
                    </div>
                    <div className='col-6'></div>                       
                </div>  

                <div className='row mb-4'>
                    <div className='col-3'>
                        <label htmlFor='sector' className=''>Sector:</label>
                        <select name='sector' className='form-control custom-select'
                            onChange={this.handleSectorChange}>
                            <option>Select sector</option>
                            {this.state.sectors.map(this.renderSectors)}
                        </select>
                    </div>
                    <div className='col-3'>
                        <label htmlFor='donor' className=''>Donor:</label>
                        <select name='donor' className='form-control custom-select' 
                        onChange={this.handleDonorChange}
                        >
                            <option>Select donor</option>
                            {this.state.donors.map(this.renderDonors)}
                        </select>
                    </div>
                    <div className='col-3'>
                        <label htmlFor='main_pr_area' className=''>Main PR area:</label>
                        <select name='mainPrArea' className='form-control custom-select' 
                        onChange={this.handleMainPrAreaChange}
                        >
                            <option>Select main pr area</option>
                            {this.state.mainPrAreas.map(this.renderMainPrAreas)}
                        </select>
                    </div>
                    <div className='col-3'></div>
                </div>  


                <div>
                    <table className="table table-hover table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th>Sector</th>
                                <th>Donor</th>
                                <th>Main pr area</th>
                                <th>Total amount</th>
                                <th>Current year amount</th>
                                <th>Next year amount</th>
                                <th>Further years amount</th>
                                <th>National cofinance amount</th>
                                <th>Currency</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.reports.map((report,index)=>{

                                totalAmountSum = totalAmountSum+report.totalAmount;
                                currentYearAmountSum = currentYearAmountSum+report.currentYearAmount;
                                nextYearAmountSum = nextYearAmountSum+report.nextYearAmount;
                                furtherYearsAmountSum = furtherYearsAmountSum+report.furtherYearsAmount;
                                nationalCofinanceAmountSum = nationalCofinanceAmountSum+report.nationalCofinanceAmount;
                                return(
                                    <tr key={index}>
                                        <td>{report.sectorName}</td>
                                        <td>{report.donorName}</td>
                                        <td>{report.mainPrAreaName}</td>
                                        <td>{<NumberFormat value={report.totalAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                                        <td>{<NumberFormat value={report.currentYearAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                                        <td>{<NumberFormat value={report.nextYearAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                                        <td>{<NumberFormat value={report.furtherYearsAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                                        <td>{<NumberFormat value={report.nationalCofinanceAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                                        <td>EUR</td>
                                    </tr>
                                )
                            })}
                            <tr style={{backgroundColor:"#ff8b3d"}}>
                                <td className={"text-center"} colspan="3">Sum</td>
                                <td>{<NumberFormat value={totalAmountSum.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                                <td>{<NumberFormat value={currentYearAmountSum.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                                <td>{<NumberFormat value={nextYearAmountSum.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                                <td>{<NumberFormat value={furtherYearsAmountSum.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                                <td colSpan="2">{<NumberFormat value={nationalCofinanceAmountSum.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        )
    }
}

export default ReportsTable;