import axios from '../../axios/app';

const SourceOfFinancingService = {

    getSourceOfFinancingPagination: (numPage) => {
        return axios.get(`rest/sourceOfFinancing/pageable?page=${numPage}&size=10`)
    },
    getAllSourceOfFinancing: () => {
        return axios.get('rest/sourceOfFinancing/all');
    },
    addSourceOfFinancing: (sourceOfFinancing) =>
    {
        return axios.post(`rest/sourceOfFinancing`, sourceOfFinancing)
    },
    editSourceOfFinancing: (sourceOfFinancing) => {
        return axios.patch(`rest/sourceOfFinancing`, sourceOfFinancing)
    },

};

export default SourceOfFinancingService;
