import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';

class CrudModalDesc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
        }
    }

    show = () => {
        this.setState({
            show: true
        });
    }

    close = () => {
        this.setState({
            show: false,
        });
    }


    render() {
        return (
            <Aux>
                <Button className={"btn " + this.props.btnClass}
                        onClick={this.show}>...</Button>
                <Modal show={this.state.show} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{fontSize:"20px",wordWrap:"break-word"}}>
                        {this.props.message}
                        </div>
                        </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.close}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </Aux>
        )
    }
}

export default CrudModalDesc;
